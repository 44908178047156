
.nisTile {
    width: 350px;
    height: fit-content;
    border-bottom: 4px solid;
}

.placeholder-items{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .placeholder-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 250px; /* Adjust height as needed */
    border: 2px solid #cecece;
    border-bottom: 5px solid #ee32a2;
  }
/*   
  @media (max-width: 768px) {
    .placeholder-item {
      max-width: 200px; 
      height: 150px; 
    }
  } */
  .spinner__container {
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
.nisTileGrid {
    display: inline-block;

}

.nisTiles_slider {
    width: 100%;
}

.btnWrapper{
    display: flex;
    justify-content: space-between;
}
.btn-noAction{
    pointer-events: none;
}
.btn-nis{
    text-transform: capitalize !important;
}

.categoryHeader_text,
.homepageHeader_text,
.categoryHeader_shorttext {
    font-size: 2.3em !important;
    letter-spacing: -1px !important;
}
.categoryHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -10px !important;

}
.homepageHeader{
    display: flex;
   justify-content: center;
   align-items: center;
   height: 44px;
  
}
.nisTiles_slider .homepageHeader {
    margin-bottom: -5px !important;
}
.nisCopyWrapperLarge{
    min-height: 130px;
}
.nisCopyWrapperLarge.active{
height: max-content;
}

.nisWrapperGrid{
    min-height: fit-content; 
}
.nisCopyWrapper{
   height: 70px;
}
.nisCopyWrapperLtile{
    height: 105px;
}
.htmlCopy {
min-height: 20px;
}
.htmlCopyLarge{
    min-height: 50px;
}
.htmlCopyLarge.htmlCopyExpand{
    min-height: 75px;
}
.htmlCopyLarge.htmlCopyShort{
    min-height: 50px;
}
.WarpperShort{
    min-height: 113px;
}
.htmlCopyLarge.active{
    min-height:fit-content;
}
.htmlCopy.active {
    min-height:fit-content;
}
.nisCopyWrapperLarge .nistileHeading {
white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  max-width: 250px;
}    
.btn-noAction{
    cursor:progress;
}
/* hover for expanding
 .htmlCopy div:hover {
    color: #474747;
    overflow: visible !important;
    font-size: 12px;
    display:block;
    height:80px;
    left:100px;
    top: 50px;
    z-index:2;

} */
.htmlCopy div {
    cursor:default;
}

.linkBtn {
    padding: 2.5px;
}
.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
}

@media screen and (min-width:992px) and (max-width:1199px){
    .categoryHeader_text {
        font-size: 1.85em;
     }
     .categoryHeader_shorttext{
        font-size: 1.8em;
     }
     .htmlCopy.htmlCopyLarge.active div {
        min-height:160px;
    }
    .htmlCopyLarge.active {
        min-height: 205px;
    }
    .nisCopyWrapperLarge .nistileHeading {
          max-width: 200px;
        }
}
@media screen and (min-width:1024px) and (max-width:1199px) {
    .htmlCopy {
        min-height: 35px;
    }
      .nisCopyWrapper{
        height: 80px;
     }
}
@media screen and (min-width:475px) and (max-width:1199px){
    .btnWrapper{
        padding: 0 15px !important;
    }
}

@media screen and (min-width:475px) and (max-width:991px) {

    .categoryHeader_text {
        font-size: 1.3em;
     }
     .categoryHeader_shorttext{
        font-size: 1.5em;
     }
     .nisCopyWrapperLarge .nistileHeading {
        max-width: 190px;
      }
      .nisCopyWrapper{
        height: 90px;
     }
}
@media screen and (min-width:670px) and (max-width:767px) {
  
     .nisCopyWrapperLarge .nistileHeading {
        max-width: 290px;
      }
}
@media screen and (min-width:361px) and (max-width:425px) {
    .homepageHeader_text {
        font-size: 1.15em !important;
     }
     .categoryHeader_shorttext{
        font-size: 1.8em;
     }
   
}
@media screen and (min-width:360px) and (max-width:475px){
    .categoryHeader_text {
        font-size: 1em;
     }
     .categoryHeader_shorttext{
        font-size: 1.3em;
     }
     .nisCopyWrapperLarge .nistileHeading {
        max-width: 190px;
      }
}
@media screen and (max-width:360px){
     .homepageHeader_text {
        font-size: 1em !important;;
        margin-right: 0  !important;
     }
     .btn-white.btn-nis{
         padding: 0 .1rem !important;
         margin-left: 0 !important;
     }
     .categoryHeader_text {
        font-size: 1em;
     }
     .categoryHeader_shorttext{
        font-size: 1.3em;
     }
     .nisCopyWrapperLarge .nistileHeading {
        max-width: 195px;
      }
}

@media screen and (max-width:320px){
    .categoryHeader_text {
        font-size: .8em;
     }
     .categoryHeader_shorttext{
        font-size: 1.2em;
     }
     .nisCopyWrapper{
        height: 90px;
     }
     .homepageHeader .btn{
         font-size: 0.8em;
     }
     .btnWrapper{
        padding: 0 15px !important;
    }
     
}

 /* header nav filter */
  .non-selectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  } 
.header-wrapper {
    display: flex;
    justify-content: flex-start;
}
.slider-wrapper::before {
    content: '';
    position: absolute;
    bottom: 30px;
    left: 27px;
    width: 100%;
    height: 3px;
    background-color: #cecece;
  }
.slider-wrapper{
    width: 95%;
    position: relative;
}

@media screen and (min-width: 768px){
.slider-wrapper  {
   margin-left: -27px;
}
}
@media screen and (min-width: 768px) and (max-width:991px) {
    .slider-wrapper::before {
        width: 97%;
       
      }
    }