/* @font-face {
    font-family: "revicons";
    fallback: fallback;
    src: url("./revicons.woff") format("woff"), url("./revicons.ttf") format("ttf"), url("./revicons.eot") format("ttf");
    } */
    .nisTiles_slider .react-multi-carousel-list {
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
      }
      @media (min-width: 992px) and (max-width: 1024px) {
        .nisTiles_slider .react-multi-carousel-list ul {
          margin-bottom: 0 !important;
        }
      }
      .nisTiles_slider .react-multi-carousel-track {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        position: relative;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        will-change: transform, transition;
      }
      .nisTiles_slider .react-multiple-carousel__arrow {
        position: absolute;
        outline: 0;
        transition: all 0.5s;
        /* border-radius: 35px; */
        z-index: 10;
        border: 0;
        background: rgba(0, 0, 0, 0.116);
        min-width: 25px;
        min-height: 35px;
        opacity: 1;
        cursor: pointer;
        color: white;
        padding: 20px 2px;
        top: 26%;
      }
      /* .react-multiple-carousel__arrow:hover:before {
          background: rgba(0, 0, 0, 0);
          color: #41aaf2;
          } */
      .nisTiles_slider .react-multiple-carousel__arrow::before {
        font-size: 40px;
        display: block;
        font-family: revicons;
        text-align: center;
        z-index: 2;
        position: relative;
        font-weight: bold;
        color: white;
      }
      .nisTiles_slider .react-multiple-carousel__arrow--left {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        left: 0.40%;
      }
      .nisTiles_slider .react-multiple-carousel__arrow--left::before {
        content: "\F104";
        font: 4rem/1 ggds-icon;
        color: #fff;
      }
      .nisTiles_slider .react-multiple-carousel__arrow--right {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        right: 0.40%;
      }
      @media (max-width:646px) {
          .nisTiles_slider .react-multiple-carousel__arrow--right {
           display: none;
          }
          .nisTiles_slider .react-multiple-carousel__arrow--left {
              display: none;
            }
        }
      @media (min-width: 992px) and (max-width: 1024px) {
          .nisTiles_slider .react-multiple-carousel__arrow--right {
            right: 0;
          }
          .nisTiles_slider .react-multiple-carousel__arrow--left {
              left: 0;
            }
        }
      .nisTiles_slider .react-multiple-carousel__arrow--right::before {
        content: "\F105";
        font: 4rem/1 ggds-icon;
        color: #fff;
      }
      .nisTiles_slider .react-multi-carousel-dot-list {
        position: absolute;
        bottom: 0;
        display: flex;
        left: 0;
        right: 0;
        justify-content: center;
        margin: auto;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
      }
      .nisTiles_slider .react-multi-carousel-dot button {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        opacity: 1;
        padding: 5px 5px 5px 5px;
        box-shadow: none;
        transition: background 0.5s;
        border-width: 2px;
        border-style: solid;
        border-color: grey;
        padding: 0;
        margin: 0;
        margin-right: 6px;
        outline: 0;
        cursor: pointer;
      }
      .nisTiles_slider .react-multi-carousel-dot button:hover:active {
        background: #080808;
      }
      .nisTiles_slider .react-multi-carousel-dot--active button {
        background: #080808;
      }
      .nisTiles_slider .react-multi-carousel-item {
        transform-style: preserve-3d;
        backface-visibility: hidden;
        display: flex;
        justify-content: center;
      }
      /* @media (min-width: 992px) and (max-width: 1024px) {
        .react-multi-carousel-item {
          max-height: 212px !important;
        }
      } */
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .nisTiles_slider .react-multi-carousel-item {
          flex-shrink: 0 !important;
        }
        .nisTiles_slider .react-multi-carousel-track {
          overflow: visible !important;
        }
      }
      